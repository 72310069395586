import {Theme} from "@mui/material/styles";
import {Dayjs} from "dayjs";
import {ChartOptions, TimeUnit} from "chart.js";
import {GREEN, ORANGE, PollutantType, PURPLE, rangeColors} from "/@/constants/chartContants";
import {ChosenGuideline, PollutantDataEntry} from "/@/types/mapTypes";
import {defraPollutantRanges, whoPollutantRanges} from "/@/constants/mapConstants";



export const createChartOptions = (chosenPollutant: PollutantType, graphData: PollutantDataEntry[], startDate: Dayjs, endDate: Dayjs, chosenGuideline: ChosenGuideline, title: string, theme: Theme ): ChartOptions => {
    const ranges = chosenGuideline === 'WHO' ? whoPollutantRanges[chosenPollutant] : defraPollutantRanges[chosenPollutant];
    const {unit, displayFormat} = getTimeScaleUnit(startDate, endDate);

    return {
        plugins: {
            title: {
                display: true,
                text: title,
                font: {
                    size: 18,
                }
            },
            annotation: {
                annotations: {
                    lowRange: {
                        drawTime: 'beforeDatasetsDraw',
                        type: 'box',
                        yMin: 0,
                        yMax: ranges.low,
                        backgroundColor: rangeColors.low,
                        borderWidth: 0,
                    },
                    mediumRange: {
                        drawTime: 'beforeDatasetsDraw',
                        type: 'box',
                        yMin: ranges.low,
                        yMax: ranges.medium,
                        backgroundColor: rangeColors.medium,
                        borderWidth: 0,
                    },
                    highRange: {
                        drawTime: 'beforeDatasetsDraw',
                        type: 'box',
                        yMin: ranges.medium,
                        yMax: 'max', // Use 'max' to extend the band to the top of the chart
                        backgroundColor: rangeColors.high,
                        borderWidth: 0,
                    },
                },
            },
            tooltip: {
                backgroundColor: theme.palette.primary.main,
                titleColor: theme.palette.primary.contrastText,
                bodyColor: theme.palette.primary.contrastText,
                padding: 10,
                callbacks: {
                    label: function (context: any) {
                        const nullValues = Object.keys(graphData)
                            .sort()
                            .reduce((acc, key, index) => {
                                if (graphData === null) {
                                    acc.push(index);
                                }
                                return acc;
                            }, [] as number[]);

                        if (nullValues.includes(context.dataIndex)) {
                            return "No Data";
                        }
                        return `${context.formattedValue} µg/m\u207B\u00B3`;
                    },
                },
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: unit,
                    tooltipFormat: 'MMM d, h:mm a', // Full date and time for tooltip
                    displayFormats: {
                        hour: displayFormat, // Format based on the range
                        day: displayFormat,
                        week: displayFormat,
                        month: 'MMM yyyy', // Monthly format
                    },
                },
                grid: {
                    display: false,
                },
                title: {
                    text: 'Time',
                    display: true,
                },
            },
            y: {
                beginAtZero: true, // Start the y-axis at 0
                max: 100, // Set the maximum value of the y-axis to 120
                grid: {
                    display: false,
                },
                title: {
                    text: "µg/m\u207B\u00B3",
                    display: true,
                },
                ticks: {
                    color: "black",
                },
            },
        },
        elements: {
            bar: {
                borderWidth: Object.keys(graphData)
                    .sort()
                    .map((key, index) => {
                        return Object.keys(graphData)?.length - 1 === index ? 3 : 1;
                    }),
            },
        },
        maintainAspectRatio: false,
        responsive: true,
    };
};

export const getColour = (value: number | null, chosenPollutant: PollutantType, isColorBlindMode: boolean, theme: Theme, chosenGuideline: ChosenGuideline) => {
    if (value === null) return "#808080";
    const ranges = chosenGuideline === 'WHO' ? whoPollutantRanges : defraPollutantRanges;
    const currentRange = ranges[chosenPollutant];
    if (value <= currentRange.low) {
        return isColorBlindMode ? theme.palette.secondary.light : GREEN;
    } else if (value > currentRange.low && value <= currentRange.medium) {
        return ORANGE;
    } else if (value > currentRange.medium) {
        return PURPLE;
    }
};
export const createChartData = (pollutantData: PollutantDataEntry[], pollutant: PollutantType, isColorBlindMode: boolean, theme: Theme, chosenGuideline: ChosenGuideline) => {
    const labels = pollutantData.map((entry) => Object.keys(entry)[0]);
    const dataPoints = pollutantData.map((entry) => entry[Object.keys(entry)[0]]);
    const backgroundColors = dataPoints.map((value) => getColour(value, pollutant, isColorBlindMode, theme, chosenGuideline));
    return {
        labels,
        datasets: [
            {
                label: pollutant,
                data: dataPoints,
                backgroundColor: backgroundColors,
                borderColor: 'black',
                borderWidth: 2,
                pointRadius: 3,
                fill: false,
            },
        ],
    };
};


export const getTimeScaleUnit = (startDate: Dayjs, endDate: Dayjs): { unit: TimeUnit, displayFormat: string } => {
    const rangeInHours = endDate.diff(startDate, 'hour');

    let unit: TimeUnit;
    let displayFormat: string;

    if (rangeInHours <= 24) {
        unit = 'hour';
        displayFormat = 'h:mm a'; // Show only time for the same day
    } else if (rangeInHours <= 3 * 24) {
        unit = 'hour';
        displayFormat = 'MMM d, ha'; // Hourly up to 3 days
    } else if (rangeInHours <= 7 * 24) {
        unit = 'day';
        displayFormat = 'MMM d, ha'; // Every 3 hours for up to 7 days
    } else if (rangeInHours <= 14 * 24) {
        unit = 'day';
        displayFormat = 'MMM d'; // Every 12 hours for up to 14 days
    } else if (rangeInHours <= 30 * 24) {
        unit = 'day';
        displayFormat = 'MMM d'; // Daily for up to 30 days
    } else {
        unit = 'week';
        displayFormat = 'MMM d'; // Weekly for more than 30 days
    }

    return {unit, displayFormat};
};

