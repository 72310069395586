import React, {useMemo} from "react";
import {AllAveragedAirQualityData} from "/@/types/mapTypes";
import {createChartData, createChartOptions} from "/@/utils/chartUtils";
import dayjs from "dayjs";
import {Box, Typography, useTheme} from "@mui/material";
import {Chart} from "react-chartjs-2";
import {useThemeContext} from "/@/context/themeContext";
import {mapPollutantToKey, PollutantType, renderNoDataMessage} from "/@/constants/chartContants";
import {useTranslation} from "react-i18next";
import {useMapContext} from "/@/context/mapContext";
import 'chartjs-adapter-date-fns';

import {
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    TimeScale
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(
    BarController,
    BarElement,
    LineController,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    annotationPlugin,
    TimeScale
);

interface AirQualityChartProps {
    pollutant: PollutantType;
    airQualityData: AllAveragedAirQualityData;
    isMapPage: boolean;
}

const AirQualityChart: React.FC<AirQualityChartProps> = ({pollutant, airQualityData, isMapPage}) => {
    const {
        chosenDateRange,
        chosenGuideline,
    } = useMapContext();

    const {isColorBlindMode, isMobile} = useThemeContext();
    const theme = useTheme();
    const {t} = useTranslation();
    const pollutantData = airQualityData[mapPollutantToKey[pollutant as PollutantType] as keyof AllAveragedAirQualityData];

    const chartData = useMemo(() => {
        return createChartData(pollutantData, pollutant, isColorBlindMode, theme, chosenGuideline);
    }, [airQualityData, pollutant, chosenGuideline]);

    const chartOptions = useMemo(() => {
        if (chosenDateRange[0] && chosenDateRange[1]) {
            const startDate = chosenDateRange[0];
            const endDate = chosenDateRange[1];
            return createChartOptions(pollutant, pollutantData, startDate, endDate, chosenGuideline, t('average_concentration', {chosenPollutant: pollutant}), theme);
        }
        // If chosenDateRange is not fully specified, return some default options
        return createChartOptions(pollutant, pollutantData, dayjs().subtract(7, 'day'), dayjs(), chosenGuideline, t('average_concentration', {chosenPollutant: pollutant}), theme);
    }, [pollutant, airQualityData, chosenDateRange, chosenGuideline]);

    const noDataAvailable = chartData.datasets[0].data.every(value => value === null || value === undefined);
    return (
        <Box position="relative" width={isMapPage ? '100%' : isMobile? "100%": "100%"} height={isMapPage ? '250px' : "300px"} key={pollutant + isMapPage ? '-map-page': 'assistant'}>
            {noDataAvailable && renderNoDataMessage()}
            <Chart
                type="line"
                data={chartData}
                aria-label={pollutant.toString()}
                options={chartOptions}
                style={{opacity: noDataAvailable ? 0.5 : 1}}
            />
        </Box>
    );
}

export default AirQualityChart;