import {useState, useCallback} from 'react';
import {SensorData, AllAveragedAirQualityData} from '/@/types/mapTypes';
import {applyOffsetToCloseSensors, decideInterval, getDateRange, getDaysBetweenDates} from '/@/utils/mapUtils';
import {useMapContext} from './mapContext';
import {useSupabase} from "/@/context/supabaseContext";
import {useJsApiLoader} from "@react-google-maps/api";
import {googleLibraries} from "/@/store/constants";
import {supabaseClient} from "/@/renderer/PageShell";



interface UseMapDataReturn {
    isMapLoaded: boolean;
    sensorData: SensorData[];
    fetchAirData: (useLiveData: boolean) => Promise<void>;
    getSensorReadingsByDateRange: () => Promise<void>;
    getAverageReadingsByBorough: () => Promise<void>;
    error: Error | null;
}

export const useMapData = (): UseMapDataReturn => {
    const {isLoaded: isMapLoaded} = useJsApiLoader({
        id: "google-index-script",
        googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API,
        libraries: googleLibraries,
    });

    const [sensorData, setSensorData] = useState<SensorData[]>([]);
    const [error, setError] = useState<Error | null>(null);
    const {supabase} = useSupabase();
    const {chosenBorough, chosenGraphSensor, chosenDateRange, setAirQualityData, chosenGraphInterval, chosenMapPinDateRange, chosenGraphMetric, chosenRadius, chosenMapPinInterval, chosenMapPinMetric} = useMapContext();


    const getMapPins = useCallback(async (useLiveData : boolean): Promise<void> => {
        try {
            if(!supabase) throw new Error('Supabase client not initialised');
            const {data, error} = await supabase.rpc(
                "get_latest_readings"
            );
            const adjustedSensorData = applyOffsetToCloseSensors(data);
            if (error) throw error;
            else {
                console.log("Adjusted sensor data: ")
                console.log(adjustedSensorData)
                if (useLiveData) {
                    const now = new Date();
                    const cutOffTime = new Date(now.setHours(now.getHours() - 2));
                    const filteredData = adjustedSensorData.filter(
                        (sensor: any) => new Date(sensor.created_at) > cutOffTime
                    );
                    setSensorData(filteredData);
                }
                else
                {
                    setSensorData(adjustedSensorData);
                }
            }
        }
        catch (error) {
            setError(error as Error);
        }
        // try {
        //     if(!supabase) throw new Error('Supabase client not initialised');
        //     const {data, error} = await supabaseClient.functions.invoke(
        //         "get-historical-air-quality-data", {
        //             body: {
        //                 "interval": 'hour',
        //                 "metric": 'avg',
        //                 "date_range": chosenMapPinDateRange,
        //                 "radius": 10,
        //                 "location_string": "London",
        //                 "group_by": "sensor",
        //                 "view": "map"
        //             }
        //         }
        //     );
        //     const adjustedSensorData = applyOffsetToCloseSensors(data);
        //     console.log("Adjusted sensor data: ")
        //     console.log(adjustedSensorData)
        //     if (error) throw error;
        //     else {
        //         if (useLiveData) {
        //             // const now = new Date();
        //             // const cutOffTime = new Date(now.setHours(now.getHours() - 2));
        //             // const filteredData = adjustedSensorData.filter(
        //             //     (sensor: any) => new Date(sensor.created_at) > cutOffTime
        //             // );
        //             // setSensorData(filteredData);
        //             setSensorData(adjustedSensorData);
        //         }
        //         else
        //             {
        //                 setSensorData(adjustedSensorData);
        //             }
        //         }
        //     }
        //     catch (error) {
        //     setError(error as Error);
        // }
    }, [chosenMapPinInterval, chosenMapPinMetric, chosenMapPinDateRange, supabase]);


    const getSensorReadingsByDateRange = useCallback(async (): Promise<void> => {
        if (supabase) {
            if (chosenDateRange[0] && chosenDateRange[1] && chosenGraphSensor) {
                const startDate = chosenDateRange[0].toISOString();
                const endDate = chosenDateRange[1].toISOString();
                // const daysInBetween = getDaysBetweenDates(startDate, endDate);
                // const interval = decideInterval(daysInBetween);

                const {data, error} = await supabaseClient.functions.invoke(
                    "get-historical-air-quality-data", {
                        body: {
                            "interval": chosenGraphInterval,
                            "metric": chosenGraphMetric,
                            "date_range": {
                                start_date: startDate,
                                end_date: endDate
                            },
                            "radius": 1,
                            "sensor_id": chosenGraphSensor.id,
                            "group_by": "interval",
                            "view": "graph"
                        }
                    }
                );
                if (error) {
                    console.error('Error fetching data:', error);
                    return Promise.reject(error);
                }
                const averageAirQualityData: AllAveragedAirQualityData = {
                    pm10: [],
                    pm25: [],
                    no2: []
                };

                const pm25Data = data["pm25"];
                const pm10Data = data["pm10"];
                const no2Data = data["no2"];

                const pm25DateRanges = Object.keys(pm25Data);
                const pm10DateRanges = Object.keys(pm10Data);
                const no2DateRanges = Object.keys(no2Data);

                for (const dateRange of pm25DateRanges) {
                    averageAirQualityData.pm25.push({[dateRange]: pm25Data[dateRange].avg_reading});
                }

                for (const dateRange of pm10DateRanges) {
                    averageAirQualityData.pm10.push({[dateRange]: pm10Data[dateRange].avg_reading});
                }

                for (const dateRange of no2DateRanges) {
                    averageAirQualityData.no2.push({[dateRange]: no2Data[dateRange].avg_reading});
                }
                setRetrievedAirQualityData(averageAirQualityData);
            } else {
                console.error('Date range is incomplete');
                return Promise.reject('Date range is incomplete');
            }
        } else {
            console.error('Supabase client not initialised');
            return Promise.reject('Supabase client not initialised');
        }
    }, [supabase, chosenDateRange, chosenGraphSensor, chosenGraphInterval, chosenGraphMetric]);

    const getAverageReadingsByBorough = useCallback(async (): Promise<void> => {
        if (supabase) {
            if (chosenDateRange[0] && chosenDateRange[1]) {

                // number of days between start and end date

                const startDate = chosenDateRange
                    [0].toISOString();
                const endDate = chosenDateRange[1].toISOString();

                const {data, error} = await supabaseClient.functions.invoke(
                    "get-historical-air-quality-data", {
                        body: {
                            "interval": chosenGraphInterval,
                            "metric": chosenGraphMetric,
                            "date_range": {
                                start_date: startDate,
                                end_date: endDate
                            },
                            "radius": 1,
                            "location_string": chosenBorough,
                            "group_by": "interval",
                            "view": "graph"
                        }
                    }
                );
                if (error) {
                    console.error('Error fetching data:', error);
                    return Promise.reject(error);
                }

                const averageAirQualityData: AllAveragedAirQualityData = {
                    pm10: [],
                    pm25: [],
                    no2: []
                };

                const pm25Data = data["pm25"];
                const pm10Data = data["pm10"];
                const no2Data = data["no2"];

                const pm25DateRanges = Object.keys(pm25Data);
                const pm10DateRanges = Object.keys(pm10Data);
                const no2DateRanges = Object.keys(no2Data);

                for (const dateRange of pm25DateRanges) {
                    averageAirQualityData.pm25.push({[dateRange]: pm25Data[dateRange].avg_reading});
                }

                for (const dateRange of pm10DateRanges) {
                    averageAirQualityData.pm10.push({[dateRange]: pm10Data[dateRange].avg_reading});
                }

                for (const dateRange of no2DateRanges) {
                    averageAirQualityData.no2.push({[dateRange]: no2Data[dateRange].avg_reading});
                }
                // console.log(averageAirQualityData)
                setRetrievedAirQualityData(averageAirQualityData);
            } else {
                console.error('Date range is incomplete');
                return Promise.reject('Date range is incomplete');
            }
        } else {
            return Promise.reject('Supabase client not initialised');
        }
    }, [supabase, chosenDateRange, chosenBorough, chosenGraphInterval, chosenGraphMetric]);

    const setRetrievedAirQualityData = useCallback( (airQualityData: AllAveragedAirQualityData): void => {
        if (airQualityData && Object.keys(airQualityData).length !== 0) {
            setAirQualityData(airQualityData);
        }
    },[setAirQualityData])

    return {
        isMapLoaded,
        sensorData,
        fetchAirData: getMapPins,
        getSensorReadingsByDateRange,
        getAverageReadingsByBorough,
        error,
    };
};
